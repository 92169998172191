import { render, staticRenderFns } from "./Blogs.vue?vue&type=template&id=df73fa16"
import script from "./Blogs.vue?vue&type=script&lang=js"
export * from "./Blogs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.4_vue-temp_kvbouyol4nkuhekjp3wtoikkcq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardBlog: require('/data/www/served.nl/served-autodeploy/releases/20240510-070717_76fd9db8/nuxt/components/cards/Blog.vue').default})
