
export default {
  props: {
    recipes: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    favorites: {
      type: Boolean,
      default: false,
    },
  },
};
