
export default {
  props: {
    blog: {
      type: Object,
      default: () => {},
    },
    model: {
      type: String,
      default: 'blog',
    },
    routeName: {
      type: String,
      default: null,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    wideImage: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      if (this.highlight) {
        return this.blog?.imageHighlight?.url;
      } else {
        return this.blog?.image?.url;
      }
    },
    isBlogOverview() {
      return this.$route.name === 'blogs';
    },
    showSmall() {
      return ['services', 'welbewust-assortiment'].includes(this.model);
    },
  },
};
