import { render, staticRenderFns } from "./MenuViewer.vue?vue&type=template&id=652c7162"
import script from "./MenuViewer.vue?vue&type=script&lang=js"
export * from "./MenuViewer.vue?vue&type=script&lang=js"
import style0 from "./MenuViewer.vue?vue&type=style&index=0&id=652c7162&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.4_vue-temp_kvbouyol4nkuhekjp3wtoikkcq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenukaartChosenMenu: require('/data/www/served.nl/served-autodeploy/releases/20240510-070717_76fd9db8/nuxt/components/menukaart/ChosenMenu.vue').default})
