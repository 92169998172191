
export default {
  props: {
    day: {
      type: Number,
      required: true,
    },
    recipe: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    days: [
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
      'Zondag',
    ],
  }),
};
