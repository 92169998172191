
import page from '~/plugins/mixins/page';
import recipes from '~/graphql/queries/recipes.gql';
import blogs from '~/graphql/queries/blogs.gql';
import categories from '~/graphql/queries/categories.gql';

export default {
  auth: false,
  mixins: [page],
  layout: 'home',
  data: () => ({
    recipes: [],
    blogs: [],
  }),
  apollo: {
    recipes: {
      query: recipes,
      variables() {
        return {
          limit: 4,
          page: 1,
        };
      },
    },
    blogs: {
      query: blogs,
      variables: {
        limit: 4,
        page: 1,
      },
    },
    categories: {
      query: categories,
      variables: {
        group: 'blogs',
      },
    },
  },
};
