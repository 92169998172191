
import weekmenu from '~/graphql/queries/weekmenu';

export default {
  apollo: {
    weekmenu: {
      query: weekmenu,
      variables() {
        return {
          week: this.week,
          year: this.year,
        };
      },
    },
  },
  data() {
    return {
      week: null,
      year: null,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 10,
        breakpoints: {
          1280: {
            slidesPerView: 8,
          },
          768: {
            slidesPerView: 6,
          },
          400: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    weeks() {
      const weeks = [];
      let date = this.$moment();

      for (let i = 0; i < 8; i++) {
        weeks.push(date);

        date = date.clone().add(1, 'week');
      }

      return weeks;
    },
    recipesPerDay() {
      const recipes = [];

      if (this.weekmenu) {
        this.weekmenu.forEach((data) => {
          // NB: array keys represents monday = 0, ..., sunday = 6
          recipes[data.weekday - 1] = data.recept;
        });
      }

      return recipes;
    },
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  created() {
    if (this.$route.params.week) {
      this.week = this.$route.params.week.week;
      this.year = this.$route.params.week.year;
    }
  },
  methods: {
    change(index) {
      const slide = this.swiper.slides[index];

      this.week = slide.dataset.week;
      this.year = slide.dataset.year;
    },
  },
};
