
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bannerImage() {
      return this.page.image?.url ?? require('~/assets/img/header.png');
    },
  },
};
